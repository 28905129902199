<template>
  <div class="p-wrap">
    <!-- 功能区 -->
    <div class="p-header">
      <div class="header-select">
        <el-form class="form" ref="emailList" :model="filter" label-width="100px">
          <el-row>
            <el-col :span="6">
              <el-form-item label="模板编号">
                <el-input v-model="filter.templateNo" placeholder="请输入模板编号"
                          @keyup.enter.native="handleQuery()"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="模板名称">
                <el-input v-model="filter.templateName" placeholder="请输入模板名称"
                          @keyup.enter.native="handleQuery()"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="设置人">
                <el-input v-model="filter.setPerson" placeholder="请输入设置人"
                          @keyup.enter.native="handleQuery()"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" class="el-button-col">
              <el-button type="default" @click="handleReset">重置</el-button>
              <el-button type="primary" @click="handleQuery()">查询</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <!-- 内容区 -->
    <div class="p-content">
      <div class="main-top">
        <div class="top-left">
          <span>模板列表</span>
          <el-popover
              placement="right"
              width="80"
              trigger="click">
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选
            </el-checkbox>
            <div class="part"></div>
            <el-checkbox-group v-model="colOptions" @change="handleCheckedCitiesChange">
              <el-checkbox v-for="item in colSelect" :label="item" :key="item"
                           style="display:block;margin-top: 6px"></el-checkbox>
            </el-checkbox-group>
            <div slot="reference" class="rowSet">
              <img class="imgSet" src="@/assets/icon/set.png">
              <span>列设置</span>
            </div>
          </el-popover>
        </div>
        <div>
          <el-button type="primary" @click="handleShowDialog('insert', '')">新增模板</el-button>
        </div>
      </div>
      <div class="p-table">
        <el-table :data="records" border stripe v-loading="tableLoading" ref="emailFormList"
                  @selection-change="handleSelection"
                  :header-cell-style="handleHeaderCellStyle">
          <el-table-column type="index" label="序号" width="60"></el-table-column>
          <el-table-column v-if="colData[0].isTrue" prop="templateNo" label="模板编号"></el-table-column>
          <el-table-column v-if="colData[1].isTrue" prop="templateName" label="模板名称"></el-table-column>
          <el-table-column v-if="colData[2].isTrue" prop="emailTitle" label="邮件主题"></el-table-column>
          <el-table-column v-if="colData[3].isTrue" prop="setPerson" label="设置人"></el-table-column>
          <el-table-column v-if="colData[4].isTrue" prop="operate" label="操作" width="110" fixed="right">
            <template slot-scope="scope">
              <div class="operate-button">
                <el-button type="text" size="medium" style="padding: 0"
                           @click="handleShowDialog('update', scope.row)">编辑
                </el-button>
                <el-button type="text" size="medium" style="padding: 0"
                           @click="handleDelete(scope.row)">删除
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="records.length > 0" class="p-page">
        <el-pagination background
                       @size-change="handleSizeChange"
                       @current-change="handleQuery"
                       :current-page.sync="filter.page"
                       :page-sizes="[15, 30, 50, 100]"
                       :page-size="filter.size"
                       layout="total, sizes, prev, pager, next"
                       :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog title="新增/编辑模板" :visible.sync="editVisible" :show-close="false">
      <mail-edit v-if="editVisible" :email-info-data="emailInfoData" ref="customerForm"
                 @handleCancelEditDialog="handleCancelEditDialog"></mail-edit>
    </el-dialog>
    <!-- 删除提示框 -->
    <el-dialog width="20%" :visible.sync="emailDeleteVisible" title="提示">
      <div class="dialog-delete" style="padding-left: 24px">
        <span>删除后不可恢复，请谨慎操作！</span>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button type="default" @click="cancelDelete">取消</el-button>
        <el-button type="primary" @click="confirmDelete">删除</el-button>
      </div>
    </el-dialog>
    <!--  删除失败提示框  -->
    <el-dialog width="20%" :visible.sync="emailNODeleteVisible" title="提示" class="dialog-no-delete">
      <div class="dialog-delete">
        <span>当前模板正在被使用，删除失败。</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {tableStyle} from '@/util/mixins'
import MailEdit from './MailEdit'
import {EMAIL_INFO_LIST} from '@/content/emailInfo'
import {getEmail, deleteById, queryStatus} from '@/service/mail'

export default {
  name: 'emailList',
  components: {MailEdit},
  mixins: [tableStyle],
  data() {
    return {
      colData: EMAIL_INFO_LIST,
      isIndeterminate: true,
      colOptions: [],      //列选项
      checkAll: false,    //全选
      selections: [], //选择行
      colSelect: EMAIL_INFO_LIST.map(i => i.title),
      // 筛选条件
      filter: {
        templateNo: '',
        templateName: '',
        setPerson: '',
        page: 1,
        size: 15
      },
      tableLoading: false,             // 表格加载loading
      versionVisible: false,           // 版本号弹窗
      importVisible: false,            // 导入弹窗
      editVisible: false,              // 编辑弹窗
      records: [],
      total: 0,                // 数据总数count
      emailDeleteVisible: false,
      emailNODeleteVisible: false,
      deleteEmailId: 0,
      selectExpended: false,
      emailInfoData: {
        id: 0,
        templateNo: ''
      }
    }
  },
  created() {
    this.handleQuery()
    this.settingOptions()
    this.handleGetlineSet()
  },
  watch: {
    colOptions(valArr) {
      const arr = this.colSelect.filter(i => valArr.indexOf(i) < 0) // 未选中
      this.colData.filter(i => {
        if (arr.indexOf(i.title) != -1) {
          i.isTrue = false
          this.$nextTick(() => {
            this.$refs.emailFormList.doLayout()
          })
        } else {
          i.isTrue = true
          this.$nextTick(() => {
            this.$refs.emailFormList.doLayout()
          })
        }
      })
      localStorage.setItem('emailInfo', JSON.stringify(this.colData))
    }
  },
  methods: {
    // 弹窗控制
    handleShowDialog(type, val) {
      this.editVisible = true
      this.emailInfoData.id = val.id
      this.emailInfoData.templateNo = val.templateNo
    },
    // 重置表单
    handleReset() {
      this.filter.orgId = 0
      this.filter.templateName = ''
      this.filter.templateNo = ''
      this.filter.setPerson = ''
    },
    // 查询
    async handleQuery(val) {
      this.filter.page = 1
      if (val) {
        this.filter.page = val
      }

      this.tableLoading = true
      const {success, data} = await getEmail(this.filter)
      this.tableLoading = false
      if (success) {
        this.records = data.records
        this.total = data.total
      }
    },
    // 切换分页条数
    handleSizeChange(val) {
      this.filter.size = val
      this.handleQuery()
    },
    // 删除商品
    async handleDelete(row) {
      this.emailDeleteVisible = true
      this.deleteEmailId = row.id
    },
    // 关闭商品dialog
    handleCancelEditDialog(status) {
      this.editVisible = false
      if (status == 'success') {
        this.handleQuery()
      }
      this.$refs.customerForm.resetForm()
    },
    /* 表格数据选中 */
    handleSelection(value) {
      this.selections = value
    },
    //获取列设置
    handleGetlineSet() {
      if (JSON.parse(localStorage.getItem('emailInfo')) != null) {
        this.colData = JSON.parse(localStorage.getItem('emailInfo'))
        if (this.colData.filter(i => i.isTrue == true).length === this.colSelect.length) {
          this.checkAll = true
          this.isIndeterminate = false
        }
        this.colOptions = JSON.parse(localStorage.getItem('emailInfo')).filter(i => i.isTrue === true).map(item => item.title) //选中
      } else {
        this.colOptions = this.colData.map(item => item.title)//默认所有
        this.checkAll = true
        this.isIndeterminate = false
      }
    },
    //重置列设置
    handleCheckReset() {
      this.colOptions = this.colSelect
      this.checkAll = true
      this.isIndeterminate = false
    },
    //列设置全选
    handleCheckAllChange(val) {
      this.colOptions = val ? this.colSelect : []
      this.isIndeterminate = false
    },
    //列设置改变
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.colSelect.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.colSelect.length
    },
    async confirmDelete() {
      const {success, data} = await deleteById(this.deleteEmailId)
      if (success) {
        // 判断是否关联
        if (!data.emailStatus) {
          this.emailNODeleteVisible = true
        } else {
          this.toast('删除邮件模板信息成功', 'success', () => this.handleQuery())
          this.invoiceBatchDeleteVisible = false
        }
      } else {
        this.toast('删除失败！', 'error')
      }
      this.emailDeleteVisible = false
    },
    /* 取消选中 */
    cancelDelete() {
      this.emailDeleteVisible = false
    },
    settingOptions() {
    }
  }
}
</script>

<style scoped lang="scss">
.p-header {
  .header-title {
    display: flex;
    padding: 16px 24px;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;

    .title-right {
      display: inline-flex;

      span {
        color: #3d94ff;
        margin-right: 20px;
        font-weight: 500;
        font-size: 16px;

        img {
          width: 18px;
          height: 18px;
          vertical-align: sub;
          margin-right: 4px;
        }
      }

      span:hover {
        cursor: pointer;
      }
    }
  }
}

::v-deep .dialog-footer {
  padding-top: 25px;
  text-align: center;
}

::v-deep .p-content {
  .main-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;

    .top-left {
      display: flex;
      justify-content: flex-start;

      span {
        line-height: 32px;
        font-size: 16px;
        color: #666666;
      }

      .rowSet {
        display: flex;
        align-items: center;
        cursor: pointer;

        .imgSet {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          margin-left: 5px;
        }

        span {
          color: #1890FF;
        }
      }
    }
  }

}

.part {
  width: 100%;
  height: 1px;
  background: #E5E5E5;
  margin: 15px 0;
}

.dialog-wrap {
  display: flex;
  height: 160px;
  flex-direction: column;

  .dialog-select {
    margin-bottom: 24px;

    label {
      width: 80px;
      text-align: left;
      display: inline-block;
    }

    label:before {
      content: "*";
      color: #F5222D;
      margin-right: 2px;
    }

    i {
      left: 140px;
      display: block;
      color: #F5222D;
      margin-top: 4px;
      position: absolute;
    }
  }

  .dialog-button {
    margin-top: 20px;
    margin-left: 110px
  }

  .backImg {
    bottom: 0;
    width: 100%;
    opacity: 0.5;
    height: 44px;
    position: absolute;
  }
}

::v-deep .el-input {
  width: 100%;
}

.el-button-col {
  padding-left: 20px;
}

::v-deep .el-dialog__body {
  text-align: inherit;
}

.dialog-no-delete {
  ::v-deep .el-dialog__body {
    padding: 30px 20px;
  }
}
</style>
